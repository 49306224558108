
$turquoise: #00BCDF;
$aqua: #91B9DC;
$red: #CE372F;
$yellow: #F9C20A;
$light_yellow: #FFE600;

$green: #85cb3f;
$dark_green: #559811;

$gray: #3D5567;
$dark_gray: #223543;
$light_gray : #9FB1BF;
$gray_super_light_2: #F7F7F7;
$gray_super_light: #FCFCFC;