.auth-panel{
	position: absolute;
	z-index: 999;

	background: #fff;
	border: 1px solid #C2C7CB;
	width: 376px;
	min-height: 264px;
	padding: 20px;

	text-align: center;

	top: 88px;
	right: 0;

	p{
		margin-top: 0;
		margin-bottom: 26px;
	}

	.button-link{
		margin: auto;

		&:last-child{
			margin-top: 28px;
		}
	}
}

@media (max-width: 400px){
	.auth-panel{
		width: 100vw;
		left: 0;
		right: auto;
	}
}