@import "../general/colors.scss";
@import "../helpers/mixins.scss";

.page-header-section {
	display: flex;
	background: $gray;
	color: #fff;
	flex: auto;

	.category {
		text-transform: uppercase;
		margin-bottom: 6px;
	}
    p {
        opacity:.6;
        text-transform: uppercase;
    }

	.date{
		color: rgba(255, 255, 255, .4);
		text-transform: uppercase;
		margin-bottom: 8px;
	}

	.event-dates{
		border-color: #fff;

	    .vertical-line{
        border-color: white;
        }
	}

	.page-title{
		margin-bottom: 50px;
	}
}


.page-header-section__main{
	flex: auto;
	padding: 25px 0;
	padding-right: 15px;
	margin-top: auto;
}

.page-header-section__thumbnail{
	width: 691px;
	min-width: 691px;
	height: 100%;
}

.page-header-section.single-news{
	min-height: 357px;
	position: relative;
	padding-right: 700px;

	.page-header-section__thumbnail{
		position: absolute;
		right: 0;
		top: 0;
		height: 413px;
	}
}


.page-header-section.single-event{
	min-height: 407px;

	.page-header-section__main{
		padding-right: 15px;
	}
}

.page-header-section.single-news,
.page-header-section.single-event{
	.page-header-section__main{
		padding-left: calc((100vw - 1088px) / 2);

		@media( max-width: 1130px){
			padding-left: 15px;
		}
	}
}


@media(max-width: 1199px){
	.page-header-section__thumbnail{
		display: none;
	}

	.page-header-section.single-news{
		padding-right: 15px;
	}
}