@import "../helpers/mixins.scss";

.breadcrumbs{
	background: linear-gradient(to right, #3D5567, #1F2B34);
	padding: 12px;

    &__items {
        @include flex-a-center;
        flex-wrap: wrap;
    }

    div, a {
        color: rgba(255, 255, 255, .5);
    }

    a:not(:last-child):after {
        content: ">";
        display: inline-block;
        margin: 0 12px;
    }
}