.ci{
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
}

.ci-herb{
	width: 28px;
	min-width: 28px;
	height: 33px;
}