@mixin flex-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-a-center{
	display: flex;
	align-items: center;
}

@mixin flex-a-start {
	display: flex;
	align-items: flex-start;
}

@mixin flex-a-end{
	display: flex;
	align-items: flex-end;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

@mixin flex-wrap-j-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@mixin flex-column{
	display: flex;
	flex-direction: column;
}

@mixin flex-column-centered{
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

@mixin transparent-button {
	background-color: transparent;
	border-width: 0;
	cursor: pointer;
}

@mixin transparent-button-no-outline {
	@include transparent-button;
	&:focus {
		outline: none;
	}
}
