.language-switcher{
	position: relative;
	text-transform: uppercase;
	cursor: pointer;



	&__active{
		display: flex;
		align-items: center;
		justify-content: center;
	
		width: 40px;
		height: 40px;
	}

	&__other{
		display: none;

		position: absolute;
		z-index: 100;

		top: 100%;
		right: -25px;
		background: #fff;
		padding: 10px;

		span{
			margin: 0 8px;
		}
	}

	&:hover{
		.language-switcher__other{
			display: flex;
		}
	}
}