.full-page-loader{
	position: fixed;
	display: flex;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	background-color: #f7f7f7;
	opacity: 1;
	z-index: 999;


	&.hidden {
		animation: hide 1s linear 1;
		opacity: 0;
		z-index: -999;
	}
}

@keyframes hide {
	from,
	40% {
		opacity: 1;
		z-index: 999;
	} 

	to {
		opacity: 0;
		z-index: -999;
	}
}