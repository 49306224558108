
.panel-container__button {
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: #F1F1F1;
  padding: 20px;
  border-style: none;
  font-size: 1.14em;
  color: black;
  cursor: pointer;

  border-top: 1px solid #CBCFD2;
  transition: 0.5s;

  &:last-child{
	  border-bottom: 1px solid #CBCFD2;
  }

  svg {
		margin-right: 10px;
		width: 23px;
		height: auto;
  }

  &.active,
  &:hover,
  &:focus {
    background-color: #85CB3F;
	 color: #ffffff;
	 

	 svg { 
		 &.fill * { fill: #fff; }
		 &.stroke * { stroke: #fff; }
	 }
  }
}