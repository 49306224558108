
.panel-container {
  min-width: 250px;
  width: 250px;
  background-color: #F1F1F1;
  transition: left .5s;

  
  .panel-container__mobile_toggle{
		display: none;
	}

  @media( max-width: 1199px){
	  position: absolute;
	  z-index: 999;
	  left: -250px;

	  .panel-container__mobile_toggle{
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 2px solid black;

			position: absolute;
			right: -50px;
			top: 10px;
		}
	}


  &.visible{
	  left: 0;

	  .panel-container__mobile_toggle{
		  right: 10px;
	  }
  }

  .panel-container__header {
	 display: flex;
	 flex-wrap: wrap;
	 justify-content: center;
	 align-items: center;
	 
    min-height: 200px;
    width: 100%;
    padding: 30px;

    img {
      margin-bottom: 20px;
		max-width: 100px;
		width: 100px;
    }

    .column {
      width: 100%;
      text-align: center;
		line-height: 1.1;

      h3 {
        white-space: pre-wrap;
      }
    }
  }

  .panel-container__body {
    width: 100%;
    min-height: 300px;

  }

  .panel-container__footer {
    padding-top: 150px;
  }
}