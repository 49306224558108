
$contrast: #ffd401;

body.contrast{
	background: #000;
	color: $contrast;

	// PLANNER

	.planer-button-container:hover {
		.wrapper-three {
		  background-color: rgba(255, 212, 1, 0.27);
		  .wrapper-two {
			 background-color: rgba(255, 212, 1, 0.47);
			 .wrapper-one {
				background-color: rgba(255, 212, 1, 0.87);
				.planer-button {
				  background: $contrast;
				  p {
					color: #000;
				 }
				}
			 }
		  }
		}
	}

	.autocomplete.searcher-suggest .noResultAlert,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblFrom span.fldContainer input::placeholder,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .btnSwitchPlaces .icon-arrow-shaft-left,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .btnSwitchPlaces .icon-arrow-shaft-right,
	.widget20 label .fieldCaption, .widget20 label .fldCaption, .ep-content.ui-dialog label .fieldCaption, .ep-content.ui-dialog label .fldCaption, .ep-modal-dialog label .fieldCaption, .ep-modal-dialog label .fldCaption,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblFrom .city-info, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblTo .city-info,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .searcher-header .search-and-buy span, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .searcher-header .powered-by span,
	.connections-searcher .calendarDatePicker #datepicker .dp-popup th,
	.connections-searcher .calendarDatePicker #datepicker .dp-popup td,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .timeSelect .layer-content .header,
	.connections-searcher .timePicker .btnHour, .connections-searcher .timePicker .btnMinute,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .timeSelect .fldContainer .fldTimeV-caption,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .dateSelect .fldContainer .fldDateV-caption {
		color: $contrast;
	}

	.autocomplete.searcher-suggest.dropdown-layer,
	.connectionsSearcherComponent .connections-searcher .timeSelect .dropdown-layer .arrivalDepartureHolder .fldDeparture, .connectionsSearcherComponent .connections-searcher .timeSelect .dropdown-layer .arrivalDepartureHolder .fldArrival,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblFrom span.fldContainer, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblTo span.fldContainer,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .btnSwitchPlaces,
	.connections-searcher .calendarDatePicker #datepicker .dp-popup h2,
	.connections-searcher .calendarDatePicker #datepicker .dp-popup,
	.connections-searcher .ommitParameter,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .dateSelect .lblDate span.fldContainer, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .dateSelect .lblTime span.fldContainer, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .timeSelect .lblDate span.fldContainer, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .timeSelect .lblTime span.fldContainer,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .buttonHolder .btnSubmit {
		background: #000;
		border-color: #ffd401;
		color: #ffd401;
		border: 1px solid;
	}


	.connectionsSearcherComponent .connections-searcher .arrivalDepartureHolder input[type=radio]:checked ~ div,
	.connections-searcher .calendarDatePicker #datepicker .dp-popup td.selected span.bgWrapper{
		color: #000;
		background: $contrast;
	}

	.connectionsSearcherComponent .connections-searcher .timeSelect .dropdown-layer,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblFrom span.fldContainer input, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblTo span.fldContainer input {
		background: #000;
		color: $contrast;
		border-color: $contrast;
	}


	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .dateSelect .fldContainer input,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .dateSelect:before,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .departureDateTime .timeSelect:before,
	.epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblFrom:before, .epConnectionSearcherPlaceholder .widget20 .searcher_Widget20 .connectionsSearcherComponent .connections-searcher form .fields .fromToHolder .lblTo:before{
		background: #000;
	}


	/* SVG */
	svg {
		&.fill {
			path, ellipse, rect, circle, line{
				fill: $contrast;
			}
		}
		&.stroke {
			path, rect, ellipse, circle, line{
				stroke: $contrast !important;
			}
		}
	
	
		&.fill-stroke {
			.fill {
				fill: $contrast !important;
			}
	
			.stroke {
				stroke: $contrast !important;
			}
		}

		&#bip {
			.white {
				fill: #000;
			}

			.red {
				fill: $contrast;
			}
		}
	}	


	.panel-container__button {
		&.active,
		&:hover,
		&:focus {
			background: $contrast;
			color: #000;
			
			svg { 
				&.fill * { fill: #000 !important; }
				&.stroke * { stroke: #000 !important; }
			}
		}
	}

	
	.arrows > button:hover,
	.sidebar__trigger.active,
	.header__link.active {
		svg {
			&.stroke{
				path, line {
					stroke: #000 !important;
				}
			}
		}
	}
	

	// ONLY COLOR
	.text-card,
	.date-picker-container .calendar-container .calendar .react-calendar__month-view__weekdays__weekday,
	.info-component-info .info-component-fields-with-icons .text a,
	.text-card .text-card-inner a,
	.section-title-facilities .name-info-facilities,
	.container .planer-history-container .planer-history-total-counter,
	.link-to-all,
	.card .card__content,
	.card .card__content .card__address > span,
	.input-container input,
	.registration-container .container__form .bottom-container .login-container button,
	.custom-container .container-fluid .row .page-title h3,
	.gray .input-container .input-label-container .input-label,
	.form-select select,
	.yellow-discount__title,
	.page-header-section .date,
	.loop-card__content-container,
	.pagination > button, .pagination > span,
	.gray-description .col h4,
	.gray-card-component .gray-card-element .gray-card-item .gray-card-element-description p,
	.gray-card-component .gray-card-element .gray-card-item .fields-with-icons .text a,
	.gray-card-component .gray-card-element .gray-card-item .gray-card-element-bottom .phone,
	.pagination > button,
	.section-info h2,
	.section-info .section-title .name-info,
	.header-actions .link,
	.map-popup-info__extra a,
	.map-popup-info__top a,
	.main-menu a,
	.carousel__head > a,
	.footer-main__address_heading,
	.breadcrumbs div, 
	.breadcrumbs a,
	.header-logo span,
	.links-tiles .row > a,
	.loop-gastronomy-post__content,
	.loop-accommodation-post__content,
	.loop-gastronomy-post__info > a,
	.loop-accommodation-post__info > a,
	.header-main__menu > a,
	.main-menu a:hover,
	.search-form input,
	.search-form input::placeholder,
	.main-menu__title,
	.loop-news-post__content,
	.loop-news-post__date,
	.footer-main a, .footer-copyright a,
	.loop-event-post__content,
	.header__link{
		color: $contrast !important;
	}


	// BACKGROUND BLACK AND COLOR CONTRAST
	.language-switcher__other,
	.date-picker-container .calendar-container .calendar,
	.info-component-description,
	.map-with-pins-filtering-info,
	.main-menu .header-actions,
	.item-container .item-wrap,
	.white-body .white-body-container,
	.container-inner .row .game-card,
	.input-container .input-label-container,
	.list-container .list-view__container,
	.container-inner,
	.button-with-underline,
	.custom-container,
	.gray,
	.textarea-container,
	.textarea-container .textarea,
	.form-select__label,
	.full-page-loader,
	.page-header-section,
	.page-title,
	.map-popup-info,
	.pictures-slider__circles button,
	.text-link-expandable-info,
	.two-carousels-one-row > div:nth-child(2),
	.main-header-external-link,
	.weather{
		background-color: #000;
		color: $contrast;
	}


	// BG BLACK + FONT CONTRAST + BORDER CONTRAST
	.breadcrumbs,
	.form-select select,
	.form-select,
	.input-container,
	.calendar-container .calendar .react-calendar__tile,
	.calendar-container .calendar .react-calendar__navigation .react-calendar__navigation__label,
	.calendar-container .calendar .react-calendar__navigation .react-calendar__navigation__arrow,
	.custom-container .container-fluid .row .col .container-inner,
	.panel-container,
	.panel-container__button,
	.day,
	.day:hover,
	.gray .input-container .input-label-container,
	.gray-description,
	.gray-card-component .gray-card-element,
	.arrows > button:disabled,
	.pic-text-info__info,
	.share-button,
	.share-button-wrapper > .share-button-list > button, .share-button-wrapper > .share-button-list > a,
	.text-link-pic,
	.footer-main,
	.main-menu,
	.footer-copyright,
	.sidebar,
	.links-tiles .row > a:hover,
	.map-with-pins-info{
		background: #000;
		color: $contrast;
		border-color: $contrast
	}

	// BG CONTRAST 
	.sidebar__trigger.active,
	.pictures-slider__circles button.active,
	.header__link.active::before,
	.main-header-external-link > span{
		background-color: $contrast;
	}


	// BG CONTRAST AND COLOR BLACK
	.single-game-header .single-game-header-gray,
	.panel-container__button.active,
	.arrows > button:hover,
	.reservation-header,
	.day.active,
	.pagination > button.active,
	.text-link-expandable-info__expandable_head{
		background: $contrast;
		color: #000 !important;
	}

	// COLOR BLACK
	.reservation-header p,
	.single-game-header .single-game-header-gray .single-game-category,
	.single-game-header .single-game-header-gray .single-game-header-gray-title p,
	.reservation-header h1{
		color: #000 !important;
	}


	// BORDER 1px solid
	.language-switcher__other,
	.loop-gastronomy-post__bottom > a:not(.button-link),
	.loop-accommodation-post__bottom > a:not(.button-link),
	.arrows > button{
		border: 1px solid $contrast;
	}


	// BORDER COLOR CONTRAST 
	.links-tiles .row > a,
	.button-with-underline:hover hr,
	.loop-event-post__dates,
	.loop-event-post__date{
		border-color: $contrast;
	}


	// DISPLAY NONE
	.main-header-external-link::before{
		display: none;
	}


	// GRAYSCALE
	.thumbnail,
	.footer-logos img,
	.item-container .item-wrap .item-image img,
	.container-inner .row .page-logo,
	.main-header-tiles-section__tile{
		filter: grayscale(100%);	
	}



	/************************* OTHER ******************************/
	.main-header-section__main{
		border-bottom: 1px solid $contrast;
	}

	.button-link{
		background: #000;
		color: $contrast;
		border-color: $contrast;
		&:hover{
			background: $contrast;
			color: #000 !important; 
		}
	}

	.auth-panel,
	.search-form,
	.main-menu{
		background: #000;
		border-color: $contrast;
		border-top: 1px solid $contrast;
		border-bottom: 1px solid $contrast;
	}


	.amounts-with-icons__item_circle > span{
		background: rgba(255, 212, 1, .4);
	}

	.amounts-with-icons__item_circle{
		background: rgba(255, 212, 1, .2);	
	}
}