@import "../general/colors.scss";
@import "../helpers/mixins.scss";


.main-menu{
	@include flex-wrap;
	background: linear-gradient(to right, #324655, #556D80);
	padding: 34px;

	position: absolute;
	z-index: 999;
	left: calc(-100vw + 88px);
	top: 88px;
	min-height: 813px;
	width: 100vw;

	font-size: 1.5em;

	visibility: hidden;

	&.visible {
		visibility: visible;
	}


	a{
		color: #fff;
		display: block;
	}


	.header-actions{
		display: none;
	}

	.search-form{
		display: none;
	}
}


$main-menu-colors:
	"tourism" $green,
	"culture" $turquoise,
	"sport" $red,
	"main" $yellow,
	"stay-updated" $aqua;

.main-menu__column{
	flex: 1;

	@each $class_name, $color in $main-menu-colors{
		&.#{$class_name}{
			.main-menu__title{
				color: $color;
			}

			.main-menu__items a:hover{
				color: $color;
			}
		}
	}
}


.main-menu__title{
	letter-spacing: 2.3px;
	font-weight: bold;
	border-bottom: 1px solid rgba( 255, 255 , 255, .5);
	padding-bottom: 30px;
	padding-left: 45px;
	text-transform: uppercase;

	strong{
		margin-right: 8px;
		cursor: pointer;
		display: none;
	}
}

.main-menu__items {
	padding-top: 45px;
	padding-left: 45px;
	line-height: 65px;
}


@media (max-width: 1700px) {
	.main-menu__items{
		padding-top: 20px;
		padding-left: 20px;
	}

	.main-menu__title{
		padding-left: 20px;
	}

	.main-menu{
		font-size: 1.2em;
		padding: 20px;
	}
}


@media (max-width: 1440px) {
	.main-menu {
		font-size: 1.14em;
	}

	.main-menu__items {
		line-height: 50px;
	}
}

@media (max-width: 1300px) {
	.main-menu {
		font-size: 1em;
	}
}


@media (max-width: 1199px){
	.main-menu__title {
		border-color: transparent;
		padding: 25px 14px;
		font-size: 1.5em;

		strong{
			display: inline-block;
		}
	}

	.main-menu__items{
		display: none;
		padding: 0 0 0 60px;
		line-height: 40px;
		font-size: 0.85em;
		font-weight: bold;
		text-transform: uppercase;
	}

	.main-menu{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		height: calc(100vh - 88px);
		// max-height: calc(100vh - 88px);

		padding: 30px 0 0 0;

		overflow: auto;
		max-height: calc( 100vh - 30px);
		min-height: auto;

		.search-form{

			padding: 20px;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(100vh - 52px);

			position: fixed;

			input{
				margin-top: 0;
			}

			svg{
				bottom: auto;
				top: 32px
			}
		}

		.header-actions {
			display: flex;
			height: 52px;
			background: #F7F7F7;
			margin-top: auto;

			// position: absolute;
			// width: 100%;
			// left: 0;
			// bottom: 0;

			.link{
				height: 52px;
				width: 52px;
			}

			.search.link.active{
				svg{ 
					position: relative; z-index: 2;
				}

				&::before{
					width: 52px;
					height: 52px;
					margin-left: -26px;
					margin-top: -26px;
					z-index: 1;
				}
			}
		}	
	}

	.main-menu__column{
		width: 100%;
		flex: 0 0;
	}

	.main-menu__column.mobile-open{

		.main-menu__title {
			background-color: rgba(255, 255, 255, .18);
		}

		.main-menu__items {
			display: block;
		}
	}
}


@media (max-width: 600px) {
	.main-menu {
		position: fixed;
		z-index: 99;
		left: 0;
		top: 55px;
		height: calc(100% - 55px);
		max-height: calc(100% - 55px);
		overflow: auto;
	}
}


@media (max-width: 374px) {
	.main-menu {
		.header-actions .link{
			margin: 0;
			flex: auto;
		}

		.main-menu__title{
			font-size: 1.25em;
		}
	}
}