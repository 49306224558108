@import "../helpers/mixins.scss";
@import "../general/colors.scss";

.header{
	position: relative;

	width: calc( 100% - 88px);
	@include flex-a-center;
	padding: 5px 0 5px 43px;
	height: 90px;
	min-height: 88px;
	
	.header__actions{
		margin-left: auto;
	}


	&.has-menu{
		.search-form,
		.auth-panel{ 
			top: 63px;
		}


		.header__link.active::before{
			width: 72px;
			margin-left: -36px;
		}
	}
}

.header-main{
	flex: auto;
	@include flex-wrap;

	.header-actions{
		margin-left: auto;
	}
}

.header-main__top{
	@include flex-a-center;
	width: 100%;

	> a:first-child{
		margin-left: auto;
	}
}


.header-logo{
	min-width: 297px;
	margin-right: 20px;

	svg{
		max-width: 100%;
	}

	span {
		display: block;
		// padding-left: 124px;
		text-align: right;
		padding-top: 8px;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
		font-weight: bold;
		color: $green;
	}
}

.header__link{
	margin: 0 20px;
	width: 40px;
	height: 40px;
	@include flex-centered;
	color: $gray;
	
	&.active {

		svg {
			&.stroke{
				path, line{
					stroke: #fff;
				}
			}

		}

		&::before{
			content: '';
			display: block;
			width: 88px;
			height: 88px;
	
			top: 50%;
			left: 50%;
			margin-top: -44px;
			margin-left: -44px;
			z-index: -1;
			background: $green;
		}
	}

	&.search{
		margin-left: 60px;
	}

	&.language-switcher{
		font-weight: bold;
		font-size: 1.14em;
	}
}


.header-main__menu{
	@include flex-a-center;
	width: 100%;
	padding: 10px 25px 0;

	font-size: 1.04em;

	&.many-items {
		font-size: 0.9em;
		padding-left: 0;

		> a {
			margin-left: 20px;
		}
	}

	> a {
		color: $gray;
		margin-left: 40px;
		text-align: center;
	}

	> a:first-child{
		margin-left: auto;
	}

    > a.active {
        font-weight: bold;
    }
}

@media (max-width: 1600px){
	.header-main__menu.many-items {
		font-size: 0.8em;
		padding-right: 8px;

		> a { 
			margin-left: 9px;
		}

		> a:first-child{
			margin-left: auto;
		}
	}
}

@media (max-width: 1440px){

	.search-form{
		width: 100vw;
	}

	.header-logo{
		min-width: 241px;
		width: 241px;
	}

	.header-main__menu{
		font-size: 1em;
		> a {
			margin-left: 15px;
		}
	}
}

@media (max-width: 1199px){
	.header-logo{
		min-width: 190px;
		width: 190px;
	}

	.header-logo span{
		font-size: 10px;
	}

	.header-main__menu{
		font-size: 0.9em;
	}

	.header-main {

		.header-main__menu,
		.header-actions{
			display: none;
		}

		.language-switcher{
			margin-left: auto;
		}
	}

	.header.has-menu .search-form, .header.has-menu .auth-panel{
		top: 88px;
	}
}


@media (max-width: 600px){
	.header-logo{
		min-width: 136px;
		width: 136px;

		span{
			font-size: 8px;
		}
	}

	.header{
		display: flex;
		height: auto;
		min-height: 55px;
		padding-left: 15px;
		width: calc(100% - 50px);
	}

	.header__link{
		margin: 0 14px;
	}

	.header__link.active::before{
		width: 55px;
		height: 55px;
		margin-left: -27.5px;
		margin-top: -27.5px;
	}


	.header{
		.auth-panel{
			top: 55px !important;
		}
	}
}


@media (max-width: 374px){
	.header-logo{
		min-width: 100px;
		max-width: 100px;
		margin: 0;

		span{
			font-size: 5px;
		}
	}
}